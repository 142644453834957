/*
 * Header
 *
 * Separated section of content at the bottom of all pages, save the homepage.
 */
.parallax-wrap{
  position: relative;
  width: 100%;
  height: 150px;
  .cloud01{
    background-image: url("/images/cloud01.png");
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: parallax 600s linear infinite;
  }
  .cloud02{
    background-image: url("/images/cloud02.png");
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: parallax 400s linear infinite;
  }
  .rain-drop{
    background-image: url("/images/rain_drop.png");
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: rains 500s linear infinite;
  }
  .header-bd{
    background-image: url("/images/header.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@keyframes rains
{
  from { background-position: 0 0; }
  to { background-position: -10000px 10000px; }
}
@keyframes parallax
{
  from { background-position: 0 0; }
  to { background-position: -10000px 0; }
}
.main-navbar{
  box-shadow: 0 1px 2px rgba(0, 5, 40, 0.5);
  .navbar-brand{
    .logo {
      position: absolute;
      top:-8rem;
      width: 170px;
      margin-right: 10px;
    }
    .logo-shrink {
      position: absolute;
      top: 0;
      width: 85px;
      margin-right: 10px;
    }
  }
  .navbar-nav .nav-link{
    font-weight: 400;
    text-transform: uppercase;
    padding: 1.2rem 0.3rem;
    font-size: 14px;
  }
  .dropdown-toggle.active-dropdown::after {
    transform: rotate(-90deg);
  }
}

.navbar-top {
  background: darken($rain-blue, 10%) !important;
}
.navbar-top a, .navbar-top > li > a{
  padding: 6px 15px !important;
  font-size: 0.85em;
}
/*=====Bootstrapthemes.co btco-hover-menu=====*/
.navbar-light .navbar-nav .nav-link {
  color: rgb(206, 206, 206);
}
.btco-hover-menu a,  .navbar > li > a {
  text-transform: capitalize;
  padding: 10px 15px;
  color: rgb(206, 206, 206)!important;
}
.btco-hover-menu .navbar-brand{
  padding: 10px 0px;
}
.btco-hover-menu .active a,
.btco-hover-menu .active a:focus,
.btco-hover-menu .active a:hover,
.btco-hover-menu li a:hover,
.btco-hover-menu li a:focus ,
.navbar>.show>a,  .navbar>.show>a:focus,  .navbar>.show>a:hover{
  color: #ffffff!important;
  background: rgba(0,0,0,0.07);
  outline: 0;
}

/*submenu style start from here*/
.btco-hover-menu .dropdown-menu {
  padding: 0px 0;
  margin: 0 0 0;
  border: 0px solid transparent !important;
  border: 0px solid rgba(0,0,0,.15);
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btco-hover-menu .dropdown-item {
  color: #cccccc;
}
/*first level*/
.btco-hover-menu .collapse ul > li:hover > a{
  background: #1d5c9b;
}
.btco-hover-menu .collapse ul ul > li:hover > a,
.navbar .show .dropdown-menu > li > a:focus,
.navbar .show .dropdown-menu > li > a:hover{
  background: rgba(0,0,0,0.07);
}
/*second level*/
.btco-hover-menu .collapse ul ul ul > li:hover > a{
  background:  rgba(0,0,0,0.07);
}

/*third level*/
.btco-hover-menu .collapse ul ul, .btco-hover-menu .collapse ul ul.dropdown-menu{
  background: #1d5c9b;
}
.btco-hover-menu .collapse ul ul ul, .btco-hover-menu .collapse ul ul ul.dropdown-menu{
  background: #1d5c9b
}
.btco-hover-menu .collapse ul ul ul ul, .btco-hover-menu .collapse ul ul ul ul.dropdown-menu{
  background: #1d5c9b
}

/*Drop-down menu work on hover*/
.btco-hover-menu{background: none;margin: 0;padding: 0;min-height:20px}

.btco-hover-menu  ul .dropdown-toggle::after{
  transform: rotate(0deg);
}
.btco-hover-menu ul ul .dropdown-toggle::after{
  transform: rotate(-90deg);
}
@media only screen and (max-width: 991px) {
  .btco-hover-menu > ul > .dropdown-toggle::after{
    transform: rotate(-90deg);
  }
  .btco-hover-menu > ul > ul > .dropdown-toggle::after{
    transform: rotate(-90deg);
  }
}
@media only screen and (min-width: 991px) {
  .btco-hover-menu .collapse ul li{position:relative;}
  .btco-hover-menu .collapse ul li:hover> ul{display:block}
  .btco-hover-menu .collapse ul li:last-child > ul{left:auto; right: 0;}
  .btco-hover-menu .collapse ul ul{position:absolute;top:100%;left:0;min-width:220px;display:none}
  .btco-hover-menu .collapse ul ul li{position:relative}
  .btco-hover-menu .collapse ul ul li:hover> ul{display:block;left: 100%;right: auto;}
  .btco-hover-menu .collapse ul ul ul{position:absolute;top:0;left:100%;min-width:220px;display:none}
  .btco-hover-menu .collapse ul ul ul li{position:relative}
  .btco-hover-menu .collapse ul ul ul li:hover ul{display:block}
  .btco-hover-menu .collapse ul ul ul ul{position:absolute;top:0;left:-100%;min-width:220px;display:none;z-index:1}

}
