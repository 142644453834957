
// Slick
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
$slick-arrow-color: black;
$slick-dot-color: black;

// intlTelInput
$flagsImagePath: "../../build/img/" !default;

// Bootstrap overrides
$border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$rain-blue: rgb(21, 71, 134) ;

$primary: lighten($rain-blue, 15%);
$secondary: rgb(136, 145, 153);
