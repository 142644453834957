.page-blog {
  .article {
    height: 100%;

    .article__cover {
      position: relative;
      display: block;
      overflow: hidden;
      height: 300px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:after {
        content: '\A';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }
    }
    .article__cover:hover{
      &:after {
        background: rgba(0, 0, 0, 0);
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }
    }

    .article__infos {
      padding: 1rem 0;
    }

    .article__title, .article__published, .article__summary {
      margin: 0;
    }

    .article__published {
      margin: 1rem 0;
      font-size: .75rem;
    }
  }
}

.page-post {
  .cover {
    position: relative;
    overflow: hidden;
    height: 250px;

    &:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .post-title {
    position: absolute;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
      border: solid 0.2rem #fff;
      border-left: 0;
      border-right: 0;
    }
  }
}


a:hover {
  text-decoration: none;
}

.article-card {
  margin: 20px 0;
  transition: 400ms;
}
.article-card:hover {
  transform: scale(1.05);
}

.article-img , .article-meta {
  display: inline-block;
  vertical-align: top;
}

.article-img {
  max-width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 100px;
}

.article-img img {
  width: 100%;
  height: 100%;
}

.article-meta {
  max-width: 360px;
  margin-top: 15px;
  margin-left: 10px;
}

.article-meta p {
  color: #404040;
}

.container-fluid {
  background-color: #FCFAFA;
}

#hero {
  padding: 80px 0;
  min-height: 700px;
  position: relative;
}


.hero-img-container {
  position: absolute;
  left: 0px;
  padding: 0;
}

.hero-img {
  max-width: 540px !important;
  height: 540px;
  overflow: hidden;
  border-radius: 100%;
}

.hero-img img {
  width: 100%;
  height: 100%;
}

.hero-title {
  background-color: #FCFAFA;
  border-radius: 150px;
  padding: 25px 60px;
  padding-right: 10px;
  margin-top: 95px;
  font-family: 'Playfair display'
}

.hero-title h1 {
  font-size: 60px;
  font-weight: bold;
  line-height: 70px;
  color: #000;
  text-decoration: none;
  transition: 500ms;

}

@media (max-width: 1200px) {

  .hero-img {
    width: 500px;
    height: 500px;
  }
}


@media (max-width: 992px) {
  #hero {
    padding: 30px 0 60px;
  }

  .hero-img-container {
    position: static;
    margin: 10px auto;
    text-align: center;
  }

  .hero-img-container .hero-img {
    display: inline-block;
  }

  .hero-title {
    padding: 0;
    margin: 0;
  }
  #hero {
    text-align: center !important;
  }
}

@media (max-width: 585px){

  .hero-img {
    width: 350px;
    height: 350px;
  }
  .hero-title h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 320px){
  .hero-img {
    width: 300px;
    height: 300px;
  }
}
