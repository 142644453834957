.comment-box-wrapper{
  display:flex;
  flex-direction:column;
  width:100%;
  margin:5px 0px;
}
.comment-box{
  display:flex;
  width:100%;
  margin-bottom: 10px;
}
.commenter-image{
  height:100px;
  width:100px;
  border-radius:50%;
  border: 1px solid $gray-200;
}
.commenter-image-sm {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid $gray-200;
}
.comment-content{
  display:flex;
  flex-direction:column;
  margin-left:10px;
  padding:4px 20px;
  border-radius:5px;
  flex: 1;
}
.commenter-head{
  display:block;
}
.commenter-head .commenter-name{
  font-size:1rem;
  font-weight:600;
}
.comment-date{
  font-size:0.8rem;
}
.comment-date i {
  margin:0 5px 0 10px;
}
.comment-body{
  padding:0;
  display:flex;
  font-size:1rem;
}
.comment-footer{
  font-size:0.9rem;
  font-weight:500;
}

.comment-footer span.comment-likes  .active .fa-heart{
  color:black;
  font-size:1rem;
}
.comment-footer span.comment-likes  .active .fa-heart{
  color:red;
}

.nested-comments{
  margin-left:50px;
}
