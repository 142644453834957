/*
 * Footer
 *
 * Separated section of content at the bottom of all pages, save the homepage.
 */

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #d9d9d9;
  background-color: $rain-blue!important;
  //background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  .footer-widget {
    #rf-logo-footer{
      position: relative;
      width: 90px;
      float: left;
      background: rgba(255,255,255, 0.7);
      margin-right: 7px;
      margin-top: 7px;
    }
    .widget-title {
      color: #f4f4f4;
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      margin-bottom: 20px;
      letter-spacing: 0.5px;
      position: relative;
      padding-bottom: 10px;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100px;
        background: lighten($rain-blue, 5%);
      }
    }
    .widget-links{
      li{
        margin-bottom: 5px;
        a {
          color: #dceeff;
          font-size: 85%;
          text-decoration: none;
          &:hover{
            color: #f4ebf8;
            text-decoration: underline;
          }
        }
      }
    }
    .widget-contact{
      .form-control{
        color: #dceeff;
        background-color: lighten($rain-blue, 5%);
        border: 1px solid lighten($rain-blue, 10%);
      }
    }
  }
  .footer-links {
    padding: 0;
    margin-bottom: 1rem;
    li {
      display: inline-block;
      margin-right: .5rem;
      a {
        color: #b5b5b5;
        font-size: 12px;
        text-decoration: none;
        &:hover{
          color: #fbf2ff;
          text-decoration: underline;
        }
      }
    }
  }
}


*[dir="rtl"] {
  .footer-links li {
    margin-right: inherit;
    margin-left: .5rem;
  }
}
.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}
/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  h1 {
    font-size:24px;
  }
}
